var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
var handleSubmit = ref.handleSubmit;
return [_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.model.id ? 'Изменить документ возврата' : 'Создать документ возврата'))]),_c('v-divider'),(_vm.loading || _vm.savingReturn)?_c('div',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"counteragent","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.counteragents,"item-value":"id","item-text":"full_name","label":"Контрагент","loading":_vm.counteragentsLoading,"required":"","error-messages":errors},on:{"change":_vm.getConsignmentsList},model:{value:(_vm.model.counteragent),callback:function ($$v) {_vm.$set(_vm.model, "counteragent", $$v)},expression:"model.counteragent"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":"doc","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.model.counteragent)?_c('v-autocomplete',{attrs:{"items":_vm.consignments,"loading":!!_vm.fetchingConsignments,"item-value":"id","item-text":"label","label":"Фактура","required":"","error-messages":errors,"no-data-text":"Ничего не найдено"},on:{"change":_vm.getConsignmentDetails},model:{value:(_vm.model.doc),callback:function ($$v) {_vm.$set(_vm.model, "doc", $$v)},expression:"model.doc"}}):_vm._e()]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[(_vm.model.doc)?_c('validation-provider',{attrs:{"name":"reg_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{ref:"consignmentDate",attrs:{"disabled":_vm.regDateIsBlocked,"close-on-content-click":false,"max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm._f("date")(_vm.model.reg_date),"label":"Дата","disabled":_vm.regDateIsBlocked,"prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.consignmentDateMenu),callback:function ($$v) {_vm.consignmentDateMenu=$$v},expression:"consignmentDateMenu"}},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.allowedRegDates,"disabled":_vm.regDateIsBlocked},on:{"input":function($event){_vm.consignmentDateMenu = false}},model:{value:(_vm.model.reg_date),callback:function ($$v) {_vm.$set(_vm.model, "reg_date", $$v)},expression:"model.reg_date"}})],1)]}}],null,true)}):_vm._e()],1),(_vm.model.doc)?[(!!_vm.fetchingConsignmentDetails)?_c('div',{staticClass:"ma-4 text-center"},[_c('v-progress-linear',{attrs:{"color":"primary","indeterminate":""}})],1):_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v("Товары для возврата: "+_vm._s((_vm.model.consignments_elements || []).length))]),_vm._l((_vm.model.consignments_elements),function(item,index){return [_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"7"}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(item.product_name))])]),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":("amount." + index),"rules":'required|maxOrZero:' + item.max_amount},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pt-0",attrs:{"required":"","error-messages":errors,"dense":"","persistent-hint":"","hint":'Максимальное кол-во для возврата: ' + item.max_amount},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", _vm._n($$v))},expression:"item.amount"}})]}}],null,true)})],1)],1)]})],2)]:_vm._e()],2)],1),_c('v-card-actions',{staticClass:"justify-space-between pb-4 mt-2 px-4"},[_c('v-btn',{attrs:{"loading":!!_vm.savingReturn,"disabled":!!_vm.savingReturn || invalid || !_vm.model.reg_date,"rounded":"","color":"success"},on:{"click":function($event){return handleSubmit(_vm.save)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.model.id ? 'Сохранить' : 'Создать')+" ")],1),_c('v-btn',{attrs:{"disabled":!!_vm.savingReturn,"loading":!!_vm.savingReturn,"rounded":"","text":"","color":"error"},on:{"click":function($event){return _vm.cancel(reset)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cancel")]),_vm._v(" Отмена ")],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }