<template>
  <v-container fluid>
    <v-row ref="filtersContainer" class="mb-1">
      <v-col cols="4" class="d-flex align-center">
        <span class="text-h5">Документы продаж</span>
        <template v-if="userService.can('sale_add_button')">
          <v-btn class="ml-3" style="vertical-align: middle;" rounded color="primary" small
                 @click="showForm()"
          >
            <v-icon left>mdi-plus</v-icon> Добавить
          </v-btn>
          <v-dialog v-model="formDialog" width="95vw" persistent>
            <consignment-form v-if="formDialog"
                            @consignment:form:saved="closeForm"
                            @consignment:form:cancel="closeForm"
            ></consignment-form>
          </v-dialog>
        </template>
      </v-col>
      <v-col cols="2">
        <v-autocomplete v-model="filter.counteragent" :items="counteragents"
                        item-value="id" item-text="full_name"
                        prepend-inner-icon="mdi-account-group" label="Контрагент"
                        :loading="!!counteragentsLoading" :disabled="!!counteragentsLoading"
                        clearable hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-autocomplete v-model="filter.currency" :items="currencies"
                        item-value="id" item-text="title"
                        prepend-inner-icon="mdi-currency-usd" label="Валюта"
                        clearable hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-menu v-model="fromDateMenu" transition="scale-transition" offset-y max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="fromDateFormatted"
                          label="с" prepend-inner-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.reg_date_after" :disabled="!!fetching"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-menu v-model="toDateMenu" transition="scale-transition" bottom left offset-y max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="toDateFormatted"
                          label="по" prepend-inner-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.reg_date_before" :disabled="!!fetching"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-data-table
      :headers="nonSortableHeaders"
      :items="consignments"
      :loading="!!fetching" :items-per-page="filter.limit"
      :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header
      no-data-text="Нет данных" loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td>{{item.reg_number}}</td>
          <td>{{item.reg_date|date}}</td>
          <td>{{item.counteragent.full_name}}
          <td>{{item.total|money(item.currency)}}</td>
          <td class="text-right">
            <v-btn class="ml-2" text small depressed color="success" @click="showDetails(item)">
              <v-icon left small>mdi-eye</v-icon>
              Показать
            </v-btn>
            <v-btn class="ml-2" icon small depressed color="primary" dark @click="showForm(item)">
              <v-icon small>mdi-pen</v-icon>
            </v-btn>

            <v-btn class="ml-2" icon small depressed color="error" @click="askRemove(item)"
                   :loading="deleting[item.id]"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages"></pagination>

    <v-dialog v-model="detailDialog" width="70vw">
      <consignment-details v-if="detailDialog"
                           @dialog:close="closeDetails"
                           @dialog:edit="editForm"
      ></consignment-details>
    </v-dialog>
  </v-container>
</template>

<script>
  import {mapState as defaultMapState, createNamespacedHelpers} from 'vuex'
  const {mapState, mapActions, mapMutations} = createNamespacedHelpers('Consignment')

  import {hasFilter, hasDialog, hasCurrency} from '@/mixins'

  import ConsignmentDetails from '@/modules/consignment/details'
  import ConsignmentForm from '@/modules/consignment/form'
  import moment from 'moment'
  import {SERVER_DATE_FORMAT, USER_DATE_FORMAT} from '@/filters'

  export default {
    name: 'consignment-list',

    mixins: [hasFilter, hasDialog, hasCurrency],

    customFilters: {
      counteragent: null,
      currency: null,
      reg_date_before: moment().format(SERVER_DATE_FORMAT),
      reg_date_after: moment().subtract(1, 'month').format(SERVER_DATE_FORMAT),
    },

    created() {
      if (this.$route.params.id) {
        this.showDetails(this.$route.params)
      }
      this.fetch(this.filter)
    },

    computed: {
      ...mapState(['data', 'fetching', 'deleting']),

      ...defaultMapState('Counteragent', {
        counteragents: state => state.data?.results || [],
        counteragentsLoading: state => !!state.fetching,
      }),

      headers() {
        return [
          {text: '#', align: 'center', width: '100'},
          {text: 'Рег номер'},
          {text: 'Дата'},
          {text: 'Контрагент'},
          {text: 'Сумма'},
          {text: 'Действия', align: 'right', sortable: false},
        ]
      },

      consignments() {
        return this.data?.results || []
      },

      fromDateFormatted() {
        return moment(this.filter.reg_date_after).format(USER_DATE_FORMAT)
      },

      toDateFormatted() {
        return moment(this.filter.reg_date_before).format(USER_DATE_FORMAT)
      },
    },

    methods: {
      ...mapActions(['fetch', 'show', 'remove']),
      ...mapMutations(['setModel', 'setConsignmentItems', 'createItem']),

      closeDetails() {
        this.setModel({})
        this.setConsignmentItems([])
        this.detailDialog = false
      },

      editForm(item) {
        this.showForm(item)
        this.detailDialog = false
      },

      showForm(item) {
        if (item && item.id) {
          this.show(item.id)
        } else {
          this.setModel({})
          this.createItem({})
        }
        this.formDialog = true
      },

      closeForm() {
        this.formDialog = false
        this.setModel({})
        this.setConsignmentItems([])
        this.fetch(this.filter)
      },
    },

    mounted() {
      this.$eventBus.$emit('setTitle', 'Документы продаж')
      this.$store.dispatch('Counteragent/fetch')
    },

    components: {
      ConsignmentForm,
      ConsignmentDetails,
    }
  }
</script>
